.top-bar {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 10px 20px;
}

.logo {
  font-size: 1.5em;
}

.account {
  font-size: 1.5em;
}

