.banner {
  height: 100px; /* Set the desired height */
  background-image: url('../assets/banner.png'); /* Ensure the correct file path */
  background-repeat: repeat-x; /* Repeat the image horizontally */
  background-size: contain; /* Scale the image to fit within the height */
  width: 100%; /* Ensure the banner spans the full width */
  position: relative;
  text-align: center;
  color: white;
}


.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Adds a semi-transparent background for better text readability */
  padding: 10px;
  border-radius: 5px;
}

.banner-text h1 {
  font-size: 2.5em; /* Increase the font size */
  margin: 0;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add shadow for better visibility */
}

.banner-text p {
  font-size: 1.5em; /* Increase the font size */
  margin: 0;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add shadow for better visibility */
}