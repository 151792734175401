.menu {
  background-color: black; /* Set background to black */
  padding: 5px 20px; /* Reduce padding for less height */
}

.menu ul {
  list-style-type: none; /* Remove default list styling */
  margin: 0;
  padding: 0;
}

.menu li {
  display: inline; /* Display menu items inline */
  margin-right: 20px; /* Space between menu items */
}

.menu a {
  color: white; /* Set text color to white */
  text-decoration: none; /* Remove underline from links */
  font-weight: bold; /* Make text bold */
  line-height: 20px; /* Adjust line height to reduce overall height */
}

.menu-icon {
  width: 20px; /* Adjust icon size as needed */
  height: 20px; /* Adjust icon size as needed */
  margin-right: 5px; /* Space between icon and text */
}

.menu a:hover {
  text-decoration: underline; /* Underline links on hover */
}
